<template>
    <div>
        <modal :name="modal_name" class="final-modal increase-width-popups-variants-addons popup-modal-all-width-height" :class="{'modalZindex':safariTrue,'modal-fix-width':loggedInUser.role == 'customer','select-addons-modal':loggedInUser.role != 'customer'}" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title text-white" v-if="variants.length>0 && addons.length>0">SELECT VARIANT / ADD-ON</span>
                    <span class="v-modal-dialog-title v-modal-title text-white" v-if="variants.length>0 && addons.length == 0">SELECT VARIANT</span>
                    <span class="v-modal-dialog-title v-modal-title text-white" v-if="addons.length>0 && variants.length == 0">SELECT ADD-ON</span>
                    <span @click="hideSelectAddons()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body pr-1">
                    <div class="" style="padding-right: 10px;">
                        <div class="newBox pb-4" v-if="variants.length>0">
                            <h6 class="mt-2 text-secondary font-weight-bold">Variants</h6>
                            <div style="overflow-x: auto;">
                                <div class="d-flex flex-wrap">
                                    <span v-for="(variant,variant_index) in variants" :key="variant_index" class="variant-addon-img ml-1 mt-1" @click="selectedVariant(variant.id)" :class="{'selected_variant':variant_id == variant.id,'image-unavailable':!variant.isAvailable && variant.is_blocked || !variant.isAvailable && !variant.is_blocked}">
                                        <p class="unavailable-image" v-if="!variant.isAvailable && variant.is_blocked">Unavailable</p>
                                        <p class="unavailable-image" v-else-if="!variant.isAvailable && !variant.is_blocked">Unavailable</p>
                                        <div class="text-center">
                                            <span class="d-block font-weight-bold word-break" style="padding: 10px 5px 10px;font-size:12px;">{{variant.variant_name}}</span>
                                            <span class=" text-center mt-1 pb-2">{{variant.formatted_variant_price}}</span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="newBox ">
                            <div class="d-flex align-items-center" style="margin-left: -10px;margin-right: -10px;">
                                <div class="box-addon-modifier mb-3 w-50 pointer mx-3" style="border-radius: 5px;" :class="{'box-addon-modifier-active': showAddons == true}" v-if="addons.length>0"  @click="expandAddons()">
                                    <div class="descriptionheader">
                                        <div class="description-text-header-left">
                                            <span class="font-weight-bold text-secondary fs-16 whiteSpace box-addon-modifier-text">Add-On</span>
                                        </div>
                                        <!-- <hr class="ml-3 addon-border w-100" style="bottom: -5px;"> -->
                                        <!-- <div class="description-text-header-right border-notes ml-0 pl-2" style="width: auto;">
                                            <span class="collapse-addon">
                                                <i v-if="showAddons == true" class="icon-chevron-double-up text-secondary icon-style-bg-white "></i>
                                                <i v-else class="icon-chevron-double-down text-secondary icon-style-bg-white"></i>
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="box-addon-modifier mb-3 w-50 pointer mx-3" style="border-radius: 5px;" :class="{'box-addon-modifier-active': show_modifiers == true}" @click="expandModifiers">
                                    <div class="descriptionheader">
                                        <div class="description-text-header-left">
                                            <span class="font-weight-bold text-secondary fs-16 whiteSpace box-addon-modifier-text">Modifier</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="showAddons == true" v-for="(section,section_index) in addons" :key="section_index">
                                <div class="infoHeader background-addons mb-2" @click="collapseAddons(section)">
                                    <div class="descriptionheader p-2 pointer">
                                        <div class="description-text-header-left">
                                            <h5>
                                                <span class="font-weight-bold text-secondary text-nowrap fs-16 text-black">{{section.addon_section_name}}</span>
                                            </h5>
                                        </div>
                                        <div class="description-text-header-right-small border-notes">
                                            <span class="collapse-addon">
                                                <i v-if="showSectionAddons.includes(section.addon_section_name)" class="icon-chevron-double-up icon-style text-black"></i>
                                                <i v-else class="icon-chevron-double-down icon-style text-black"></i>
                                            </span>
                                            <!-- <span class="collapse-addon" @click="collapseAddons(section)">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/main-collapse.svg" v-if="showSectionAddons.includes(section.addon_section_name)" style="width:25px;right: -2px!important;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg"  style="width:25px;right: -2px!important;" v-else>
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap" v-if="showSectionAddons.includes(section.addon_section_name)">
                                    <div class="addon-main mt-4 ml-2 mb-10" v-for="(addon,addon_index) in section.addons" :key="addon_index">
                                        <div class="variant-addon" :class="{'selected_variant':selected_addon_ids.includes(addon._id)}">
                                            <div @click="selectAddon(addon,addon_index)">
                                                <avatar
                                                    class="product-avatar content-center pt-7"
                                                    v-if="addon.image_url == ''"
                                                    :username="addon.name.replace(/ .*/,'')"
                                                    :src="addon.image_url"
                                                    :size="50"
                                                    :rounded="false"
                                                    color="#f5a623"
                                                    background-color="transparent">
                                                </avatar>
                                                <img :src="addon.image_url" class="avatar avatar-addons" :alt="addon.name" v-else>
                                                <p class="unavailable-addons" v-if="addon.quantity == 0">Unavailable</p>
                                            </div>
                                            <div v-if="selected_addon_ids.includes(addon._id)" class="input-prepend-append bg-orange addon-quantity d-flex pl-1 pr-1">
                                                <a class="btn-prepend pt-1 pointer" @click="decreaseQuantity(addon,addon_index)">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" style="width:25px;transform: rotate(0deg);">
                                                </a>
                                                <input @change="addonQty(addon,addon_index)" :name="'item_qty'+addon_index.toString()" oncopy="return false" onpaste="return false"  v-model.number="addon.selected_quantity" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" type="number" min="0" max="1000" class="form-control bg-orange w-52 text-center">
                                                <a class="btn-append pt-1 pointer" @click="increaseQuantity(addon,addon_index)">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" style="width:25px">
                                                </a>
                                            </div>
                                        </div>
                                        <span class="d-flex justify-content-start mt-4">
                                            <div>
                                                <el-tooltip class="item text-center" effect="dark" content="Non-Veg" placement="top" v-if="addon.type_id.name == 'Non-Veg'">
                                                <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg">
                                                </el-tooltip>
                                                <el-tooltip class="item text-center" effect="dark" content="Veg" placement="top" v-else-if="addon.type_id.name == 'Veg'">
                                                    <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg">
                                                </el-tooltip>
                                                <el-tooltip class="item text-center" effect="dark" content="Egg" placement="top" v-else-if="addon.type_id.name == 'Egg'" >
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg">
                                                </el-tooltip>
                                                <el-tooltip class="item text-center" effect="dark" content="Pescatarian" placement="top" v-else-if="addon.type_id.name == 'Pescatarian'">
                                                <img  style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                                </el-tooltip>

                                            </div>
                                            <div class="col addon-col">
                                                <span class="word-break text-center mt-3 font-weight-bold">{{addon.name}}</span>
                                                <span class="d-block  mt-1 font-weight-bold">{{addon.formatted_price}}</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="pl-3" v-show="show_modifiers == true">
                                <div v-if="modifiers.length > 0">
                                    <div>
                                        <h1 class="fw-bold" style="color: #000; font-weight: 600; font-size: 16px;">Modifiers</h1>
                                        <div class="row">
                                            <div v-for="(modifier, modifier_index) in modifiers" :key="modifier_index+'mmddff'" class="mt-2 d-inline-block">
                                                <div class="form-check form-check-inline mr-5">
                                                    <label class="form-check-label position-relative flex-column text-center" style="width: 120px; padding-left: 0 !important;">
                                                        <div class="modifier-default pointer position-relative w-100" :class="{'modifier-default-selected': modifier.show_items}">
                                                            <avatar v-if="modifier.image == '' || modifier.image == null"
                                                                :username="modifier.modifier_name.trim()"
                                                                :src="''"
                                                                :size="100"
                                                                :rounded="true"
                                                                style="width: 50px !important; height: 50px !important; margin-left: auto !important; margin-right: auto !important;"
                                                                color="#000"
                                                            ></avatar>
                                                            <img v-else :src="modifier.image" width="50" height="50" alt="Logo" />
                                                            <input type="radio"
                                                                class="form-check-input position-absolute pointer" style="left: 20px;top: 10px;" @change="selectModifier($event, modifier,modifier_index)"
                                                                v-model="modifier.show_items"
                                                                name="modifier_name" />
                                                            <span class="radio-icon d-none"></span>
                                                            <span v-if="modifier.modifier_as == 'mandatory'" class="text-danger position-absolute" style="color: #F44336 !important;bottom: -20px;right: -5px;padding: 0;">*</span>
                                                        </div>
                                                        <span class="text-capitalize d-block position-relative" style="width: 120px;padding-left: 5px !important;padding-top: 2px;">
                                                            <span class="fs-13" style="word-break: break-all;">{{modifier.modifier_name}} </span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="show_item_modifier">
                                        <div v-for="(modifier, modifier_index) in modifiers" :class="{'d-block':modifier.show_items, 'd-none': !modifier.show_items}" :key="modifier_index+'mmvddff'">
                                            <h1 v-if="modifier.show_items == true" class="fs-14 mt-5 text-capitalize" style="color: #000; font-weight: 600; font-size: 16px;">{{modifier.modifier_name}} Item List</h1>
                                            <div v-if="modifier.show_items == true" class="row modifier-checkbox">
                                                <div v-for="(modifier_item, modifier_group_item_index) in modifier.modifier_list" :key="modifier_group_item_index+'mmindexddff'" class="d-inline-block m-3 modifier-checkbox-ui">
                                                    <label class="form-check-label position-relative flex-column text-center" style="width: 120px; padding-left: 0 !important;">
                                                        <div class="modifier-default pointer w-100" :class="{'modifier-default-selected': modifier_item.checked}">
                                                            <avatar
                                                                :username="modifier_item.item.trim()"
                                                                :src="''"
                                                                :size="100"
                                                                :rounded="true"
                                                                style="width: 50px !important; height: 50px !important; margin-left: auto !important; margin-right: auto !important;"
                                                                color="#000"
                                                            ></avatar>
                                                            <input type="checkbox"
                                                                class="form-check-input position-absolute pointer" style="left: 20px;top: 10px;" @change="modifierItemChange($event, modifier_index, modifier_group_item_index)"
                                                                v-model="modifier_item.checked"
                                                                name="modifier_name" />
                                                            <span class="checkbox-icon d-none"></span>
                                                        </div>
                                                        <span class="text-capitalize d-block" style="padding-left: 5px !important;padding-top: 2px;width: 120px;">
                                                            <span class="fs-13" style="word-break: break-all;">{{modifier_item.item}}</span>
                                                        </span>
                                                    </label>
                                                    <!-- <el-checkbox v-model="modifier_item.checked" @change="modifierItemChange($event, modifier_index, modifier_group_item_index)" class="mr-5 text-capitalize">{{modifier_item.item}}</el-checkbox> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="text-center">
                                    <span class="fs-16 fw-600">Modifiers Not Found</span>
                                </div>
                            </div>
                            <!-- <div class="pl-3 pt-3">
                                <div v-if="selected_modifiers.length > 0">
                                    <div>
                                        <h1 class="" style="color: #000; font-weight: 600; font-size: 16px;">Modifiers Selected</h1>
                                    </div>
                                    <div class="row modifier-checkbox">
                                        <div v-for="(modifier_item, modifier_group_item_index) in selected_modifiers" :key="modifier_group_item_index+'mmindexddff'" class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6 mb-2">
                                            <span>
                                                <span class="fs-14 text-black fw-bold text-capitalize">{{modifier_item.modifier_name}}</span> - {{modifier_item.modifier_item}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class ="newBox">
                            <div class="pointer" @click="expandNotes()" >
                                <span class="collapse-addon">
                                    <i v-if="showNotes == true" class="icon-chevron-double-up icon-style mt-1"></i>
                                    <i v-else class="icon-chevron-double-down icon-style mt-1"></i>
                                </span>
                                <!-- <span class="collapse-addon mt-2" @click="expandNotes()" :class="{'mt-4':showAddons == true}">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" :class="{'iconRotate':showNotes == true}" class="w-30 rotateIcon">
                                </span> -->
                                <span class="d-flex text-secondary font-weight-bold fs-16 mt-2 border-notes"  :class="{'mt-4':showAddons == true}">Notes</span>
                                <hr class="ml-14 addon-border mr-8">
                            </div>
                            <textarea v-if="showNotes == true" class="form-control addon-note mt-2" :maxlength="noteLength" v-model="note"></textarea>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                {{noteLength - note.length}} / {{noteLength}}</p>
                            <!-- <div class="pointer position-relative" @click="expandPreferences()">
                                <span class="collapse-addon position-absolute" style="right: 5px;">
                                    <i v-if="showPreferences == true" class="icon-chevron-double-up icon-style mt-1"></i>
                                    <i v-else class="icon-chevron-double-down icon-style mt-1"></i>
                                </span>
                                <span class="mt-4 d-flex text-secondary font-weight-bold fs-16">Preferences</span>
                                <hr class="ml-26 addon-border mr-8">
                            </div>
                            
                            <span v-show="preferences.length>0 && showPreferences == true" class="preferences mt-2" v-for="(name,pref_index) in preferences" :key="pref_index">
                                {{name}}
                            </span>
                            <span v-if="showPreferences == true" v-show="preferences.length==0" class="d-block font-weight-bold mt-2 text-center">
                                Customer Preference not Available
                            </span> -->
                            <div class="total-addon row no-gutters align-items-center p-2 mt-4">
                                <div><img class="b-r-7" :src="product.image_url"></div>
                                <div class="col pl-2 pr-2">
                                    <div class="d-flex justify-content-between w-100">
                                        <div class="d-flex">
                                            <el-tooltip class="item" effect="dark" content="Non-Veg" placement="top" v-if="product.product_type == 'Non-Veg'">
                                            <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg">
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Veg" placement="top" v-else-if="product.product_type == 'Veg'">
                                                <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg">
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Egg" placement="top" v-else-if="product.product_type == 'Egg'" >
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg">
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Pescatarian" placement="top" v-else-if="product.product_type == 'Pescatarian'">
                                                <img  style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                            </el-tooltip>

                                            <span class="text-white font-weight-bold" :class="{'ml-1':product.product_type != ''}">{{product.product_name}}</span>
                                        </div>
                                        <span class="text-white font-weight-bold text-uppercase">Total Price: {{this.formatPrice(productPrice)}}</span>
                                    </div>
                                    <hr style="border:0.7px solid rgb(132, 136, 140);">
                                    <div class="d-flex justify-content-between">
                                        <span class="text-white font-weight-bold d-flex align-items-center">{{selected_addons.length}} Add-On</span>
                                        <div class="input-prepend-append d-flex pl-1 pr-1 align-items-center">
                                            <a class="btn-prepend pt-1 pointer" @click="decreaseProductQty()">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qty-minus.svg" style="width:25px;">
                                            </a>
                                            <input oncopy="return false" onpaste="return false" @input="productQty(product)" v-validate="{required:true}" name="item_qty" v-model.number="items_qty" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" type="number" min="1" :max="product.product_quantity" class="form-control text-center add-custom-addon">
                                            <a class="btn-append pt-1 pointer" @click="increaseProductQty()">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-qty.svg" style="width:25px">
                                            </a>
                                        </div>
                                        <div class="save-addon d-flex align-items-center">
                                            <!-- <el-tooltip class="item" effect="dark" content="Item Summary" placement="top">
                                                <span @click="saveItemAddon()" class="pointer">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/saved.svg">
                                                </span>
                                            </el-tooltip> -->
                                            <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                <span class="ml-3 pointer" @click="deleteAddons()">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/trash_addon.svg">
                                                </span>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5 d-block">
                    <div class="d-flex mt-3">
                        <button v-if="!editOrder && loggedInUser.role != 'customer' && !hold_status" @click="fastpayAddon()" class="pointer d-flex justify-content-between align-items-center bg-green-image pl-2 pr-2 pt-1 pb-1 b-r-7 w-100">
                            <span class="text-white font-weight-bold d-flex align-items-center fs-16">Fast Pay</span>
                            <span>
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/fast-pay.svg">
                            </span>
                        </button>
                        <button v-if="loggedInUser.role != 'customer' && loggedInUser.enable_fast_checkout" @click="fastCheckout()" :class="{'ml-3':!editOrder}" class="pointer d-flex justify-content-between bg-blue-image align-items-center pl-2 pr-2 pt-1 pb-1 b-r-7 w-100">
                            <span class="fs-16 text-white font-weight-bold d-flex align-items-center">Fast Checkout</span>
                            <span>
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/fast-checkout.svg">
                            </span>
                        </button>
                        <button :disabled="savedaddons" v-if="editOrder" @click="saveAddons()" class="pointer d-flex justify-content-between bg-blue-image align-items-center pl-2 pr-2 pt-1 pb-1 ml-3 b-r-7 bg-orange-image" :class="{'w-25':(loggedInUser.role != 'customer' && loggedInUser.enable_fast_checkout),'w-100':(loggedInUser.role == 'customer' || !loggedInUser.enable_fast_checkout)}">
                            <span class="font-weight-bold fs-16">Save</span>
                            <!-- <span>
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/save-arrow.svg">
                            </span> -->
                        </button>
                    </div>
                    <button :disabled="savedaddons" v-if="!editOrder" @click="saveAddons()" class="pointer w-100 d-flex justify-content-between align-items-center bg-blue-image p-3 bg-orange-image mt-3 b-r-7">
                        <span class="font-weight-bold fs-16">Save</span>
                        <span>
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/save-arrow.svg">
                        </span>
                    </button>
                </div>
            </div>
        </modal>
        <payment-method v-if="payshow" modal_name="method_payment_addon" :summary="summary" :is_credit_card_used="false" :grand_total_value="grand_total_value" :grand_total_amount="grand_total_amount" :fastPay="true" @close="closePaymentMethod"></payment-method>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
          </sweet-modal>
          <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
          </sweet-modal>
    </div>
</template>
<script>
import { SweetModal } from "sweet-modal-vue";
import Avatar from 'vue-avatar'
import orderServices from './mixins/orders'
import { EventBus } from './eventBus/nav-bar-event';
const PaymentMethod = () => import('./PaymentMethod')
export default {
    mixins: [orderServices],
    props:['modal_name','product','order_index','editOrder','selectedCourse','order','hold_status'],
    data(){
        return{
            safariTrue:false,
            showAddons: true,
            showSectionAddons: [],
            showNotes: true,
            showPreferences: true,
            addons:[],
            variants:[],
            variant_id: "",
            selected_addons:[],
            preferences:[],
            selected_addon_ids:[],
            note:'',
            items_qty:1,
            summary:'',
            grand_total_value:'',
            grand_total_amount:'',
            modal_msg:'',
            savedaddons:false,
            payshow:false,
            variant_response:false,
            isTablet: false,
            show_modifiers: false,
            show_item_modifier: false,
            modifiers: [],
            selected_modifiers: [],
            noteLength: 200,
            select_mandatory_modifier: false
        }
    },
    components:{
        Avatar,
        PaymentMethod,
        SweetModal
    },
    methods:{
        selectModifier(event, modifier, index) {
            this.modifiers.forEach((el, index) => {
                el.show_items = false;
            })
            this.show_item_modifier = true;
            this.modifiers[index].show_items = true;
            this.$forceUpdate();
        },
        modifierItemChange(event, modifier_index, modifier_item_index) {
            if(event.target.checked == true) {
                this.selected_modifiers.push({
                    _id: this.modifiers[modifier_index]._id,
                    modifier_name: this.modifiers[modifier_index].modifier_name,
                    modifier_item: this.modifiers[modifier_index].modifier_list[modifier_item_index].item
                });
            } else {
                const index = this.selected_modifiers.map(object => object.modifier_item).indexOf(this.modifiers[modifier_index].modifier_list[modifier_item_index].item);
                this.selected_modifiers.splice(index, 1);
            } 
            this.$forceUpdate();
        },
        formatPrice (value) {
            let val = (value / 1).toFixed(2).replace(',', '.')
            let currency_symbol = this.product.formatted_variant_price.split('')[0]
            return currency_symbol+''+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        fastpayAddonSubmitConfirm(){
            this.payshow = true
            this.$http
            .post('orders/grand_total', { order_id: this.order_index._id })
            .then(response => {
                this.grand_total_value = response.data.value.toFixed(2)
                this.grand_total_amount = response.data.amount
                this.$modal.show('method_payment_addon')
            })
        },
        async fastpayAddonSubmit(){
            try {
                this.$http.get("/cashier/is_cashier_session_active").then(res => {
                this.modal_msg = res.data.reason;
                if (res.data.status_id == 1) {
                    this.$store.commit("setCashRegister",res.data.response.cash_register_details);
                    this.fastpayAddonSubmitConfirm()
                } else {
                    this.$toasted.global.error(res.data.reason);
                }
                });
            } catch (reason) {
                this.$toasted.global.error(reason);
            }
        },
        async fastpayAddon(){
            this.$validator.validate().then(result => {
                if (result == true) {
                    let params = {
                        "order_id": this.order_index._id,
                        "product_id": this.product.product_id,
                        "variant_id": this.product.variant_id,
                        "quantity":this.items_qty,
                        "vendor_id":this.loggedInUser.outlet_id,
                        "note": this.note,
                        "addons":this.selected_addons,
                        "is_customizable":this.product.is_customisable,
                        "course":this.selectedCourse,
                        "web":true,
                        "is_combo":this.product.is_combo,
                        "modifiers": []
                    }
                    localStorage.setItem('modifiers',JSON.stringify(this.modifiers))
                    let modifiers = JSON.parse(localStorage.getItem('modifiers'))
                    params.modifiers = modifiers
                    // this.select_mandatory_modifier = true;
                    params.modifiers.forEach((modifier, modifier_index) => {
                        delete modifier.show_items
                        modifier.modifier_list.forEach((modifier_item, modifier_item_index) => {
                            // if(modifier.modifier_as == 'mandatory' && modifier_item.checked == true){
                            //     this.select_mandatory_modifier = false;
                            // }
                            if(modifier_item.checked){
                                params.modifiers[modifier_index].modifier_list[modifier_item_index] = params.modifiers[modifier_index].modifier_list[modifier_item_index].item
                            }
                            else{
                                // params.modifiers[modifier_index].modifier_list.splice(modifier_item_index,1)
                                delete params.modifiers[modifier_index].modifier_list[modifier_item_index]
                            }
                        })
                        modifier.modifier_list = modifier.modifier_list.filter((x) => x != 'empty')
                    });
                    params.modifiers.forEach((modifier, modifier_index) => {
                        if(modifier.modifier_list.length == 0) {
                            // params.modifiers.splice(modifier_index,1)
                            delete params.modifiers[modifier_index]
                        }
                    });
                    params.modifiers = params.modifiers.filter((x) => x != 'empty')
                    // if(this.select_mandatory_modifier) {
                    //     this.$toasted.global.error('Please Select Mandatory Modifiers.')
                    //     this.savedaddons = false
                    // } else {
                    return new Promise(async (resolve, reject) => {
                        let response = await this.$http.post("/mobile/orders/items", params)
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.summary = response.data.response.order
                            this.fastpayAddonSubmit()
                            this.$modal.hide(this.modal_name);
                        } else {
                            reject(response.data.reason)
                            if(response.data.reason == 'Variant Id is Required.'){
                                this.$toasted.global.error('Please Select Variant');
                            }else{
                                this.$toasted.global.error(response.data.reason);
                            }
                        }
                    })
                    // }
                }
                else{
                     if(this.items_qty == 0){
                        this.$toasted.global.error('Item Quantity must be greater than 0');
                    }
                }
            })
        },
        closePaymentMethod(){
            this.payshow = false
            this.$modal.show(this.modal_name);
            this.$modal.hide('method_payment_addon')
        },
        saveItemAddon(){
            this.$validator.validate().then(result => {
                if (result == true) {
                    let params = {
                        "order_id": this.order_index._id,
                        "product_id": this.product.product_id,
                        "variant_id": this.variant_id,
                        "quantity":this.items_qty,
                        "vendor_id":this.loggedInUser.outlet_id,
                        "note": this.note,
                        "addons":this.selected_addons,
                        "is_customizable":this.product.is_customisable,
                        "course":this.selectedCourse,
                        "is_combo":this.product.is_combo,
                        "modifiers": []
                    }
                    localStorage.setItem('modifiers',JSON.stringify(this.modifiers))
                    let modifiers = JSON.parse(localStorage.getItem('modifiers'))
                    params.modifiers = modifiers
                    // this.select_mandatory_modifier = true;
                    params.modifiers.forEach((modifier, modifier_index) => {
                        delete modifier.show_items
                        modifier.modifier_list.forEach((modifier_item, modifier_item_index) => {
                            // if(modifier.modifier_as == 'mandatory' && modifier_item.checked == true){
                            //     this.select_mandatory_modifier = false;
                            // }
                            if(modifier_item.checked){
                                params.modifiers[modifier_index].modifier_list[modifier_item_index] = params.modifiers[modifier_index].modifier_list[modifier_item_index].item
                            }
                            else{
                                // params.modifiers[modifier_index].modifier_list.splice(modifier_item_index,1)
                                delete params.modifiers[modifier_index].modifier_list[modifier_item_index]
                            }
                        })
                        modifier.modifier_list = modifier.modifier_list.filter((x) => x != 'empty')
                    });
                    params.modifiers.forEach((modifier, modifier_index) => {
                        if(modifier.modifier_list.length == 0) {
                            // params.modifiers.splice(modifier_index,1)
                            delete params.modifiers[modifier_index]
                        }
                    });
                    params.modifiers = params.modifiers.filter((x) => x != 'empty')
                    // if(this.select_mandatory_modifier) {
                    //     this.$toasted.global.error('Please Select Mandatory Modifiers.')
                    //     this.savedaddons = false
                    // } else {
                    return new Promise(async (resolve, reject) => {
                        let response = await this.$http.post("/mobile/orders/items", params)
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.$emit('hideAddons');
                            this.$emit('item_summary');
                            this.$modal.hide(this.modal_name);
                        } else {
                            reject(response.data.reason)
                            if(response.data.reason == 'Variant Id is Required.'){
                                this.$toasted.global.error('Please Select Variant');
                            }else{
                                this.$toasted.global.error(response.data.reason);
                            }
                        }
                    })
                    // }
                }
                else{
                     if(this.items_qty == 0){
                        this.$toasted.global.error('Item Quantity must be greater than 0');
                    }
                }
            })
        },
        expandNotes(){
            if(!this.showNotes){
                this.showNotes = true;
            }
            else{
                this.showNotes = false;
            }
        },
        expandPreferences(){
            if(!this.showPreferences){
                this.showPreferences = true;
            }
            else{
                this.showPreferences = false;
            }
        },
        collapseAddons(section){
            if(this.showSectionAddons.find(x => x === section.addon_section_name)){
                this.showSectionAddons = this.showSectionAddons.filter(function(x) {
                    if (x !== section.addon_section_name) {
                        return x;
                    }
                });
            }
            else{
                this.showSectionAddons.push(section.addon_section_name)
            }
        },
        fastCheckout(){
            this.$validator.validate().then(result => {
                if (result == true) {
                    let params = {
                        "order_id": this.order_index._id,
                        "product_id": this.product.product_id,
                        "variant_id": this.variant_id,
                        "quantity":this.items_qty,
                        "vendor_id":this.loggedInUser.outlet_id,
                        "note": this.note,
                        "addons":this.selected_addons,
                        "is_customizable":this.product.is_customisable,
                        "course":this.selectedCourse,
                        "is_combo":this.product.is_combo,
                        "modifiers": []
                    }
                    localStorage.setItem('modifiers',JSON.stringify(this.modifiers))
                    let modifiers = JSON.parse(localStorage.getItem('modifiers'))
                    params.modifiers = modifiers
                    // this.select_mandatory_modifier = true;
                    params.modifiers.forEach((modifier, modifier_index) => {
                        delete modifier.show_items
                        modifier.modifier_list.forEach((modifier_item, modifier_item_index) => {
                            // if(modifier.modifier_as == 'mandatory' && modifier_item.checked == true){
                            //     this.select_mandatory_modifier = false;
                            // }
                            if(modifier_item.checked){
                                params.modifiers[modifier_index].modifier_list[modifier_item_index] = params.modifiers[modifier_index].modifier_list[modifier_item_index].item
                            }
                            else{
                                // params.modifiers[modifier_index].modifier_list.splice(modifier_item_index,1)
                                delete params.modifiers[modifier_index].modifier_list[modifier_item_index]
                            }
                        })
                        modifier.modifier_list = modifier.modifier_list.filter((x) => x != 'empty')
                    });
                    params.modifiers.forEach((modifier, modifier_index) => {
                        if(modifier.modifier_list.length == 0) {
                            // params.modifiers.splice(modifier_index,1)
                            delete params.modifiers[modifier_index]
                        }
                    });
                    params.modifiers = params.modifiers.filter((x) => x != 'empty')
                    // if(this.select_mandatory_modifier) {
                    //     this.$toasted.global.error('Please Select Mandatory Modifiers.')
                    //     this.savedaddons = false
                    // } else {
                    return new Promise(async (resolve, reject) => {
                        let response = await this.$http.post("/mobile/orders/items", params)
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.$emit('hideAddons');
                            this.$emit('fastCheckoutAddons',this.product)
                            this.$modal.hide(this.modal_name);
                        } else {
                            reject(response.data.reason)
                            if(response.data.reason == 'Variant Id is Required.'){
                                this.$toasted.global.error('Please Select Variant');
                            }else{
                                this.$toasted.global.error(response.data.reason);
                            }
                        }
                    })
                    // }
                }
                else{
                    if(this.items_qty == 0){
                        this.$toasted.global.error('Item Quantity must be greater than 0');
                    }
                }
            })
        },
        async saveAddons(){
            this.savedaddons = true
            this.$validator.validate().then(result => {
                if (result == true) {
                    let params = {
                        "order_id": this.order_index._id,
                        "product_id": this.product.product_id,
                        "variant_id": this.variant_id,
                        "quantity":this.items_qty,
                        "vendor_id":this.loggedInUser.outlet_id,
                        "note": this.note,
                        "addons":this.selected_addons,
                        "is_customizable":this.product.is_customisable,
                        "course":this.selectedCourse,
                        "is_combo":this.product.is_combo,
                        "modifiers": []
                    }
                    localStorage.setItem('modifiers',JSON.stringify(this.modifiers))
                    let modifiers = JSON.parse(localStorage.getItem('modifiers'))
                    params.modifiers = modifiers
                    // this.select_mandatory_modifier = true;
                    params.modifiers.forEach((modifier, modifier_index) => {
                        delete modifier.show_items
                        modifier.modifier_list.forEach((modifier_item, modifier_item_index) => {
                            // if(modifier.modifier_as == 'mandatory' && modifier_item.checked == true){
                            //     this.select_mandatory_modifier = false;
                            // }
                            if(modifier_item.checked){
                                params.modifiers[modifier_index].modifier_list[modifier_item_index] = params.modifiers[modifier_index].modifier_list[modifier_item_index].item
                            }
                            else{
                                // params.modifiers[modifier_index].modifier_list.splice(modifier_item_index,1)
                                delete params.modifiers[modifier_index].modifier_list[modifier_item_index]
                            }
                        })
                        modifier.modifier_list = modifier.modifier_list.filter((x) => x != 'empty')
                    });
                    params.modifiers.forEach((modifier, modifier_index) => {
                        if(modifier.modifier_list.length == 0) {
                            // params.modifiers.splice(modifier_index,1)
                            delete params.modifiers[modifier_index]
                        }
                    });
                    params.modifiers = params.modifiers.filter((x) => x != 'empty')
                    // if(this.select_mandatory_modifier) {
                    //     this.$toasted.global.error('Please Select Mandatory Modifiers.')
                    //     this.savedaddons = false
                    // } else {
                        return new Promise(async (resolve, reject) => {
                            let response = await this.$http.post("/mobile/orders/items", params)
                            this.savedaddons = false
                            if (response.data.status_id == 1) {
                                resolve(response.data)
                                this.$emit('hideAddons');
                                this.$modal.hide(this.modal_name);
                                localStorage.removeItem('modifiers');
                            } else {
                                reject(response.data.reason)
                                if(response.data.reason == 'Variant Id is Required.'){
                                    this.$toasted.global.error('Please Select Variant');
                                }else{
                                    this.$toasted.global.error(response.data.reason);
                                }
                            }
                        })
                    // }
                }
                else{
                    this.savedaddons = false
                    if(this.items_qty == 0){
                        this.$toasted.global.error('Item Quantity must be greater than 0');
                    }

                }
            })
        },
        hideSelectAddons(){
            this.$emit('hideAddons');
            this.$modal.hide(this.modal_name);
        },
        expandAddons(){
            this.showAddons = true;
            this.show_modifiers = false;
        },
        expandModifiers(){
            this.showAddons = false;
            this.show_modifiers = true;
        },
        selectedVariant(variantIndex){
            this.variant_id = variantIndex
            this.items_qty = 1
            this.selected_addons = [],
            this.selected_addon_ids = [];
            this.loadChangeVariant(this.variant_id);
        },
        async loadChangeVariant(variant_id) {
            this.variant_response = true
            let response  = await this.getAddons(variant_id);
            this.addons = response.addons
            this.modifiers = response.modifiers
            if(this.addons.length == 0) {
                this.showAddons = false;
                this.show_modifiers = true;
            } else {
                this.showAddons = true;
                this.show_modifiers = false;
            }
            this.modifiers.forEach((el, index) => {
                el.show_items = false
                el.modifier_list = el.modifier_list.map((str, index_item) => ({ item: str, checked: false, id: index_item+1}));
            })
            if(this.product.hasVariant){
                this.variants = response.variants
                setTimeout(() => {
                   this.variant_response = false
                }, 1000);
                this.addons.forEach((section) => {
                    this.showSectionAddons.push(section.addon_section_name)
                })
            }
            this.preferences = response.preferences
        },
        selectAddon(selected_addon,index){
            if(selected_addon.quantity <= 0){
                // this.$toasted.global.error('Quantity is Unavailable');
            }
            else{
                if(this.selected_addons.find(x => x._id === selected_addon._id)){
                    selected_addon.selected_quantity = 0
                    this.selected_addons = this.selected_addons.filter(function(x) {
                        if (x._id !== selected_addon._id) {
                            return x;
                        }
                    });
                    this.selected_addon_ids = this.selected_addon_ids.filter(function(x,index) {
                        if (x !== selected_addon._id) {
                            return x;
                        }
                    });
                }
                else{
                    selected_addon.selected_quantity = 1
                    this.selected_addon_ids.push(selected_addon._id)
                    this.selected_addons.push({'_id':selected_addon._id,'quantity':selected_addon.selected_quantity,'price':selected_addon.price});
                }
            }
        },
        increaseQuantity(selected_addon,index){
            let addon_price = selected_addon.price
            selected_addon.selected_quantity++
            addon_price = selected_addon.selected_quantity * selected_addon.price
            this.selected_addons.forEach((e, i) => {
                if (e._id == selected_addon._id) {
                    if(selected_addon.selected_quantity > selected_addon.quantity) {
                    }
                    else {
                        this.selected_addons[i].quantity++
                        this.selected_addons[i].price = addon_price
                    }
                }
            });
            if(selected_addon.selected_quantity > selected_addon.quantity){
               selected_addon.selected_quantity = selected_addon.quantity
               this.$toasted.global.error('Quantity cannot be greater than quantity of an Add-On');
            }
            this.$forceUpdate();
        },
        decreaseQuantity(selected_addon,index){
            let addon_price = selected_addon.price
            selected_addon.selected_quantity--
            addon_price = selected_addon.selected_quantity * selected_addon.price
            if(selected_addon.selected_quantity <= 0 && selected_addon.quantity>0){
               selected_addon.selected_quantity = 0
            }
            if(selected_addon.selected_quantity <= 0){
                selected_addon.selected_quantity = 0
                this.selected_addons = this.selected_addons.filter(function(x) {
                    if (x._id !== selected_addon._id) {
                        return x;
                    }
                });
                this.selected_addon_ids = this.selected_addon_ids.filter(function(x,index) {
                    if (x !== selected_addon._id) {
                        return x;
                    }
                });
            }
            this.selected_addons.forEach((e, i) => {
                if (e._id == selected_addon._id) {
                    this.selected_addons[i].quantity--
                    this.selected_addons[i].price = addon_price
                }
            });

        },
        async getAddons(variant_id){
            let params = {
                "product_id": this.product.product_id,
                "order_id":this.order_index._id,
                "variant_id": variant_id
            }
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post("/add_ons/addon/get_addons_for_category", params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async loadAddons(variant_id){
            console.log(this.hold_status)
            this.variant_response = true
            let response  = await this.getAddons(variant_id);
            this.addons = response.addons
            this.modifiers = response.modifiers
            if(this.addons.length == 0) {
                this.showAddons = false;
                this.show_modifiers = true;
            } else {
                this.showAddons = true;
                this.show_modifiers = false;
            }
            this.modifiers.forEach((el, index) => {
                el.show_items = false
                el.modifier_list = el.modifier_list.map((str, index_item) => ({ item: str, checked: false, id: index_item+1}));
            })
            if(this.product.hasVariant){
                this.variants = response.variants
                this.variant_id = this.variants.id
                setTimeout(() => {
                   this.variant_response = false
                }, 1000);
                if(this.product.hasVariant){
                    if(this.variants.length){
                        if(this.variants[0].isAvailable || !this.variants[0].is_blocked){
                            this.variant_id = this.variants[0].id
                        }
                    }
                }
                this.addons.forEach((section) => {
                    this.showSectionAddons.push(section.addon_section_name)
                })
            }
            this.preferences = response.preferences
        },
        increaseProductQty(){
            this.items_qty++
            if(!this.product.hasVariant && this.items_qty > this.product.product_quantity){
                this.items_qty = this.product.product_quantity
            }
            else if(this.product.hasVariant && this.items_qty > this.product.variant_quantity){
                this.items_qty = this.product.variant_quantity
            }
        },
        decreaseProductQty(){
            this.items_qty--
            if(this.items_qty <= 0){
                this.items_qty = 1
            }
        },
        productQty(product){
            if(!this.product.hasVariant && this.items_qty > this.product.product_quantity){
                this.items_qty = this.product.product_quantity
                this.$toasted.global.error('Quantity cannot be greater than quantity of an Item');
            }
            else if(this.product.hasVariant && this.items_qty > this.product.variant_quantity){
                this.items_qty = this.product.variant_quantity
                this.$toasted.global.error('Quantity cannot be greater than quantity of an Item');
            }
            else if(this.items_qty == ''){
                this.items_qty = 0
            }
        },
        addonQty(addon,addon_index){
            let addon_price = addon.price
            if(addon.selected_quantity > addon.quantity){
                addon.selected_quantity = addon.quantity
                this.$toasted.global.error('Quantity cannot be greater than quantity of an Add-On');
            }
            else if(addon.selected_quantity == '' ){
                addon.selected_quantity = 0
            }
            if(addon.quantity>0){
                this.selected_addons.forEach((e, i) => {
                    if (e._id == addon._id) {
                        this.selected_addons[i].quantity = addon.selected_quantity
                        addon_price = addon.price * addon.selected_quantity
                        this.selected_addons[i].price = addon_price
                    }
                });
            }
            this.selected_addons.forEach((e, i) => {
                if (e._id == addon._id) {
                    this.selected_addons[i].quantity = addon.selected_quantity
                    addon_price = addon.price * addon.selected_quantity
                    this.selected_addons[i].price = addon_price
                }
            });
        },
        deleteAddons(){
            this.selected_addons = []
            this.selected_addon_ids = []
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        productPrice(){
            if(this.variant_id == ""){
                return (this.items_qty * this.product.product_price) + (this.addonPrice * this.items_qty)
            }
            else{
                let variant_index = null
                this.variants.forEach((variant,index) => {
                    if(variant.id == this.variant_id){
                        variant_index = index
                    }
                })
                if(this.variants.length && this.variant_response == false && this.variants[variant_index].variant_price == '-'){
                    this.variants[variant_index].variant_price = 0
                }
                if(this.variant_response == false){
                    return (this.items_qty * this.variants[variant_index].variant_price) + (this.addonPrice * this.items_qty)
                }
            }
        },
        addonPrice(){
            return this.selected_addons.reduce((total, addon) => total += parseFloat(addon.price), 0)
        }
    },
    created(){
        this.loadAddons(this.product.variant_id);
    },
    mounted(){
        // Eventbus.$on('updateHoldStatus', (hold_status) => {
        //     this.hold_status = hold_status
        // })
        // console.log(this.hold_status)
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
}
</script>
<style scoped>
.unavailable-addons {
    opacity: 0.6 !important;
    pointer-events: none;
    position: relative;
    vertical-align: middle;
    top: 28%;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-weight: 600;
    height: 26px;
    line-height: 26px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.88);
    text-align: center;
}
.whiteSpace{
    white-space: nowrap;
}
.addon-col{
    padding-left: 8px;
    margin-top: 2px;
    padding-right: 8px;
}
.avatar-addons {
    font-size: 50px !important;
    line-height: 50px;
    background-color: transparent!important;
}
.avatar-addons {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background-color: transparent!important;
}
.word-break{
    word-break: break-word;
}
.variant-addon{
    cursor: pointer;
    width: 120px;
    height: 88px;
    text-align: center;
    border-radius: 7px;
    padding-top: 17px;
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
    background-color: #eff1f4;
}
.variant-addon-img{
    cursor: pointer;
    width: 174px;
    min-width: 174px;
    height: 95px;
    text-align: center;
    border-radius: 7px;
    padding-top: 5px;
    -webkit-box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.selected_variant{
    background-color: #00448b;
    color: #fff;
}
.addon-border{
  position: relative;
  bottom: 11px;
  border-bottom: 0.1px solid #b2b2b3;
}
.collapse-addon{
    position: relative;
    display: block;
    float: right;
    cursor: pointer;
}
.v-modal-layout{
    overflow: initial !important;
}
.addon-main{
    width: 121px;
    margin-left: 7px;
}
.addon-note{
    height: 96px!important;
    border: 1px solid #b2b2b3!important;
    border-radius: 7px!important;
    padding: 10px 6px!important;
}
.addon-quantity{
    position: relative;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.w-52{
    width: 62px !important;
}
.total-addon{
    box-shadow: 0 2px 4px 0 rgba(162, 141, 141, 0.16);
    background-image: linear-gradient(97deg, #005dae 20%, #004fe3 99%);
    border-radius: 7px;
}
.add-custom-addon{
    width: 75px !important;
    color: #fff !important;
    border-radius: 8px!important;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #fff!important;
}
.bg-green-image{
    background-image: linear-gradient(83deg, #1a9347 8%, #1ca04d 53%, #20b256 93%);
}
.bg-blue-image{
    background-image: linear-gradient(92deg, #004fe3 0%, #005dae 103%);
}
.bg-orange-image{
    background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
}
.bg-orange-image:focus{
    outline: 0px auto -webkit-focus-ring-color!important;
    color: #303031!important;
}
.b-r-7{
    border-radius: 7px;
}
.w-30{
    width: 28px;
}
.rotateIcon{
    transform: rotate(180deg);
}
.iconRotate{
    transform: rotate(0deg) !important;
}
.content-center{
    margin: 0 auto;
    position: relative;
    bottom: 12px;
}
.preferences{
    border-radius: 18px;
    border: solid 1px #b2b2b3;
    padding: 5px 10px 5px 10px;
    display: inline-block;
    margin-left: 4px;
}
.unavailable-image {
    opacity: 0.8 !important;
    position: absolute;
    vertical-align: middle;
    top: 30%;
    /* left: 42%; */
    transform: translate(-50%, -50%);
    color: red;
    font-weight: 600;
    height: 26px;
    line-height: 26px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.88);
    text-align: center;
}
.image-unavailable {
  opacity: 0.6 !important;
  pointer-events: none;
  position: relative
}
/* .final-modal .v-modal-header {
    background-color: #00448b !important;
} */
.final-modal .v-modal-header {
    background-color: #00448b !important;
}
.final-modal .v-modal-title {
    color: #fff !important;
}
.newBox {
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #00448b;
}
.icon-style {
    color: #00448b !important;
    bottom: 8px !important;
    font-size: 20px !important;
    float: right;
}
/* .infoHeader>.descriptionheader .description-text-header-right {
    border-bottom: none !important;
} */
/* .infoHeader>.descriptionheader .description-text-header-right-small {
    border-bottom: none !important;
} */
.border-notes {
    border-bottom: none !important;
}

.box-background {
    background-color :#fff;
    /* color : #fff; */
}
.background-addons {
    background-color :#ffea92;
    color : #fff;
}
.icon-style-bg-white{
    color: #00448b;
    bottom: 8px !important;
    font-size: 20px !important;
    float: right;
}
.box-addon-modifier {
    padding: 7px 20px;
    border: solid 1px #0d84f2;
    background-color: #fff;
}
.box-addon-modifier-text {
    color: #1d2126;
}
.box-addon-modifier-active {
    border: solid 1px #0d84f2;
    background-color: #0d84f2;
}
.box-addon-modifier-active .box-addon-modifier-text {
    color: #fff;
}
.modifier-default {
    background-color: #eff1f4;
    padding: 10px;
    border-radius: 5px;
}
.modifier-default-selected {
    background-color: #206ad3;
}
</style>